module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Portfolio website","short_name":"My App","description":"Example project for the Gatsby Head API","lang":"en","display":"minimal-ui","start_url":"/","background_color":"rgb(219, 219, 219)","theme_color":"#51a44c","theme_color_in_head":false,"icon":"/opt/buildhome/repo/src/images/icon.png","icon_options":{"purpose":"any maskable"},"icons":[{"src":"/opt/buildhome/repo/src/images/icon.png","sizes":"512x512","type":"png"},{"src":"/opt/buildhome/repo/src/images/icon.png","sizes":"256x256","type":"ping"}],"cache_busting_mode":"none","legacy":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
